import React, { useEffect } from 'react'
import useMobileDetect from '../hooks/mobileHook';

const DemoChatbot = () => {
  const isMobile = useMobileDetect()
  useEffect(() => {
    let minimizeDemoTimeoutId;
    function minimizeChatbot() {
      const container = document.getElementById('demo-chatbot-container');
      minimizeDemoTimeoutId = setTimeout(() => {
        container.style.width = '82px';
        container.style.height = '82px';
      }, 400);

      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    }
    function maximizeChatbot(isMobile) {
      clearTimeout(minimizeDemoTimeoutId);
      const container = document.getElementById('demo-chatbot-container');
      let newWidth;
      let newHeight;
      if (isMobile) {     
        newHeight = '100%';
        newWidth = '100%';
      } else {
        newHeight = '680px';
        newWidth = '380px';
      }
      container.style.width = newWidth;
      container.offsetWidth;
      container.style.height = newHeight;
    }
    function handleResize(event) {
      if (event.origin !== "https://kwixi.ai") return;
      var container = document.getElementById('demo-chatbot-container');
      const state = event.data.state;
      const action = event.data.action;
      if (action === 'resize' && state) {
        if (state === 'closed') {
          minimizeChatbot()
        } else if (state === 'popup') {
          container.style.width = '320px'
          container.style.height = '190px'
          document.documentElement.style.overflow = 'auto';
          document.body.style.overflow = 'auto'
        } else if (state === 'open') {
          maximizeChatbot(isMobile)
          if (isMobile) {
            document.documentElement.style.setProperty('overflow', 'hidden', 'important');
            document.body.style.setProperty('overflow', 'hidden', 'important');
          } else {
            document.documentElement.style.overflow = 'auto';
            document.body.style.overflow = 'auto'
          }
        }
      } else if (action === 'setBottomPosition') {
        container.style.bottom = `${event.data.bottom}px`
      } else if (action === 'setZIndex') {
        container.style.zIndex = `${event.data.zIndex}`;
      }
    }

    window.addEventListener('message', handleResize);

    return () => {
      window.removeEventListener('message', handleResize);
    };
  }, []);

  return (
    <div id="demo-chatbot-container"
      style={{
      position: 'fixed',
      right: '0px',
      bottom: '0',
      zIndex: 1000,
      width: '76px',
      height: '76px',
      transition: 'width 0.2s ease-in-out, height 0.2s ease-in-out',
      overflow: 'hidden',
      border: 'none'
    }}>
      <iframe id="demo-chatbot-iframe"
          // src="http://localhost:3003/client/AXTRD9142/"
          src='https://kwixi.ai/client/AXTRD9142'
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            overflow: 'hidden',
            overscrollBehavior: 'none'
          }}>
      </iframe>
    </div>
  )
}

export default DemoChatbot